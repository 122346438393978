import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

interface CampaignModalContext {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const CampaignModalContext = createContext<CampaignModalContext | undefined>(undefined);

export const CampaignModalProvider = ({ children }: { children: ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <CampaignModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
    </CampaignModalContext.Provider>
  );
};

export const useCampaignListModal = () => {
  const context = useContext(CampaignModalContext);
  if (!context) {
    throw new Error('useCampaignListModal must be used within a CampaignModalProvider');
  }
  return context;
};
